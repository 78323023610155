import React from "react"

const UiUxIcon = ({ width, height }) => {
  return (
    <>
      <svg
        version="1.1"
        baseProfile="tiny"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={width}
        height={height}
        viewBox="0 0 49.9 49.9"
        overflow="visible"
      >
        <defs></defs>
        <g id="Group_2_2_" transform="translate(898.297 -267.245)">
          <g id="Group_5_1_" transform="translate(601.387 295.245)">
            <g id="Group_1_2_">
              <path
                id="Path_5_2_"
                d="M-1449.8-23.3V14c-0.1,0.4-0.2,0.7-0.4,1.1c-0.7,1.5-2,2.1-3.6,2.1c-6.8,0-13.7,0-20.5,0
				h-0.6c0,0.3,0,0.6,0,0.9c-0.1,2.3-1.6,3.8-3.9,3.8c-5.7,0-11.4,0-17.1,0c-0.4,0-0.8,0-1.2-0.1c-1.7-0.5-2.7-1.9-2.7-3.8
				c0-12.9,0-25.8,0-38.7c0-1.2,0-2.4,0-3.6c0-1.2,0.5-2.3,1.5-3c0.5-0.3,1.1-0.5,1.6-0.7h18.6c0.1,0,0.1,0.1,0.2,0.1
				c1.8,0.4,2.8,1.6,3,3.4c0,0.4,0,0.8,0.1,1.2L-1449.8-23.3z M-1498.2-20.7v31.3h21.9v-31.3L-1498.2-20.7z M-1474.7,4.3v11.5h20.9
				c1.7,0,2.6-0.9,2.6-2.6v-28.5h-23.5v2.4h21.4V4.3H-1474.7z M-1498.2,12.2c0,2,0,3.8,0,5.7c0,1.7,0.9,2.5,2.5,2.5
				c5.7,0,11.3,0,17,0c1.6,0,2.5-0.9,2.5-2.5c0-1.8,0-3.5,0-5.3c0-0.2,0-0.3,0-0.5L-1498.2,12.2z M-1463.3-3.1l5.4-5.6l3.1,2.7v-5.4
				h-20v13l0.1,0.1l7.8-8L-1463.3-3.1z M-1451.3-16.8v-5h-23.5c0,0.2,0,0.3,0,0.5c0,1.3,0,2.6,0,3.9c0,0.5,0.1,0.6,0.6,0.6
				c6.9,0,13.8,0,20.8,0L-1451.3-16.8z M-1476.2-22.2c0-0.6,0-1.2,0-1.8c0-1.7-0.9-2.6-2.5-2.6c-2.9,0-5.8,0-8.8,0
				c-2.8,0-5.6,0-8.3,0c-1.2,0-2.1,0.7-2.2,1.8c-0.1,0.9,0,1.7,0,2.6L-1476.2-22.2z M-1466.9,2.7h12.2c0-2.2,0-4.4,0-6.5
				c0-0.1,0-0.2-0.1-0.3c-1-0.9-1.9-1.7-2.9-2.6L-1466.9,2.7z M-1466.6-4.2l-6.9,7c1.4,0,2.8,0,4.3,0c0.2,0,0.4-0.1,0.5-0.3
				c1.4-1.4,2.7-2.8,4.1-4.2c0.1-0.1,0.2-0.2,0.3-0.3L-1466.6-4.2z"
              />
              <path
                id="Path_6_2_"
                d="M-1479-16.2v16h-16.5v-16H-1479z M-1480.5-1.7v-13h-13.5v13h1.5c-0.1-2.3,0.6-4.2,2.7-5.4
				c-1.6-2.1-1.6-4-0.1-5.4c1.3-1.3,3.4-1.4,4.8-0.2c0.7,0.6,1.2,1.4,1.3,2.2c0.2,1.3-0.2,2.6-1.2,3.4c1,0.5,1.7,1.3,2.2,2.3
				c0.5,1,0.5,2.1,0.5,3.1L-1480.5-1.7z M-1491-1.7h7.3c0.2-1.6-0.2-3-1.6-3.9c-1.2-0.8-2.8-0.9-4,0
				C-1490.7-4.8-1491.1-3.4-1491-1.7L-1491-1.7z M-1485.1-10c0-1.2-1-2.2-2.2-2.2c0,0,0,0,0,0h0c-1.2,0-2.2,1-2.2,2.2
				c0,1.2,1,2.2,2.2,2.2C-1486.1-7.8-1485.2-8.7-1485.1-10C-1485.1-9.9-1485.1-9.9-1485.1-10L-1485.1-10L-1485.1-10z"
              />
              <path id="Path_7_1_" d="M-1479.4,3.2v1.4h-6.8V3.2H-1479.4z" />
              <path id="Path_8_1_" d="M-1493,6.1h5.8v1.4h-5.8V6.1z" />
              <path id="Path_9_2_" d="M-1495,4.6V3.2h4.4v1.4H-1495z" />
              <path id="Path_10_2_" d="M-1485.7,7.5V6.1h1.4v1.4H-1485.7z" />
              <path id="Path_11_1_" d="M-1481.4,6.1v1.4h-1.4V6.1H-1481.4z" />
              <path id="Path_12_1_" d="M-1489.1,3.2h1.4v1.4h-1.4V3.2z" />
              <path
                id="Path_13_1_"
                d="M-1462.5,5.7h9.2v5.3h-9.2V5.7z M-1461,7.2v2.4h6.3V7.2H-1461z"
              />
              <path
                id="Path_14_1_"
                d="M-1464,11.1h-9.2V5.8h9.2V11.1z M-1465.5,7.2h-6.3v2.4h6.3L-1465.5,7.2z"
              />
              <path
                id="Path_15_1_"
                d="M-1484.6,16.3c0,1.5-1.2,2.7-2.6,2.7c-1.5,0-2.7-1.2-2.7-2.6c0-1.5,1.2-2.7,2.6-2.7
				c0,0,0,0,0,0C-1485.8,13.6-1484.6,14.8-1484.6,16.3z M-1487.2,15.1c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2
				c0.7,0,1.2-0.6,1.2-1.2C-1486,15.6-1486.6,15.1-1487.2,15.1L-1487.2,15.1z"
              />
              <path id="Path_16_1_" d="M-1461.4-9.9v1.4h-5.8v-1.4H-1461.4z" />
              <path id="Path_17_1_" d="M-1468.7-9.9v1.4h-1.4v-1.4H-1468.7z" />
              <path id="Path_18_1_" d="M-1471.6-8.5h-1.4v-1.4h1.4V-8.5z" />
              <path id="Path_19_1_" d="M-1472.8-18.6V-20h16.6v1.4H-1472.8z" />
              <path
                id="Path_20_1_"
                d="M-1453.3-20.1v1.4h-1.4c0-0.4,0-0.8,0-1.2c0-0.1,0.1-0.2,0.3-0.3
				C-1454-20.1-1453.7-20.1-1453.3-20.1z"
              />
              <path id="Path_21_1_" d="M-1484.3-25v1.4h-5.8V-25H-1484.3z" />
            </g>
          </g>
        </g>
      </svg>
    </>
  )
}

export default UiUxIcon
