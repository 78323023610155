import React from "react"

const IllustrationIcon = ({ width, height }) => {
  return (
    <>
      <svg
        version="1.1"
        baseProfile="tiny"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={width}
        height={height}
        viewBox="0 0 49.9 49.9"
        overflow="visible"
      >
        <g id="Group_3_1_" transform="translate(1153.297 -545.245)">
          <g id="Group_4_1_" transform="translate(1823.982 1980.245)">
            <g id="Group_3-2_1_" transform="translate(76 -1407)">
              <path
                id="Path_38_1_"
                d="M-3022.5,5.7c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1
				C-3021.5,6.2-3021.9,5.7-3022.5,5.7C-3022.5,5.7-3022.5,5.7-3022.5,5.7z"
              />
              <path
                id="Path_39_1_"
                d="M-3008.2-28h-40.2c-2.7,0-4.9,2.2-4.9,4.9V6.7c0,2.7,2.2,4.9,4.9,4.9h15.2v4.5h-1
				c-2.7,0-4.9,2.2-4.9,4.9c0,0.5,0.4,1,1,1c0,0,0,0,0,0h19.5c0.5,0,1-0.4,1-1c0,0,0,0,0,0l0,0c0-2.7-2.2-4.9-4.9-4.9h-1v-4.5h15.2
				c2.7,0,4.9-2.2,4.9-4.9v-29.8C-3003.4-25.8-3005.5-28-3008.2-28z M-3048.4-26h40.2c1.6,0,2.9,1.3,2.9,2.9v25h-4.5v-22.4
				c0-0.5-0.4-1-1-1c0,0,0,0,0,0h-7.8c-0.5,0-1,0.4-1,1l0,0V1.8h-4.9c0-1,0-2-0.1-3c-0.1-2.6-0.3-5.2-0.8-7.8c2-1.5,2.6-4.2,1.3-6.4
				l-3.4-5.9c-0.3-0.5-0.9-0.6-1.3-0.4c-0.1,0.1-0.3,0.2-0.4,0.4l-3.4,5.9c-1.2,2-0.7,4.6,1.1,6.2c-0.4,2.6-0.7,5.3-0.8,8
				c0,1-0.1,2-0.1,3h-4.9v-14.6c0-0.1,0-0.3-0.1-0.4l0,0l-3.9-7.8c-0.2-0.5-0.8-0.7-1.3-0.4c-0.2,0.1-0.3,0.2-0.4,0.4l-3.9,7.8l0,0
				c-0.1,0.1-0.1,0.3-0.1,0.4V1.8h-4.5v-25C-3051.3-24.7-3050-26-3048.4-26z M-3017.6,1.8v-21.5h5.9v2h-1c-0.5,0-1,0.4-1,1
				c0,0,0,0,0,0l0,0c0,0.5,0.4,1,1,1h1v2.9h-1c-0.5,0-1,0.4-1,1l0,0c0,0.5,0.4,1,1,1h1v2.9h-1c-0.5,0-1,0.4-1,1v0l0,0
				c0,0.5,0.4,1,1,1l0,0h1v3.9h-1c-0.5,0-1,0.4-1,1l0,0c0,0.5,0.4,1,1,1h1v2L-3017.6,1.8z M-3028.2-8c0.4,0,0.7,0,1.1-0.1
				c0.2,1.2,0.4,3.2,0.6,6h-3.6c0.2-2.8,0.4-4.9,0.6-6.1C-3029.1-8-3028.6-8-3028.2-8L-3028.2-8z M-3028.2-9.9
				c-1.6,0-2.9-1.3-2.9-2.9c0-0.5,0.1-1,0.4-1.4l2.5-4.4l2.5,4.4c0.8,1.4,0.3,3.2-1.1,4C-3027.1-10.1-3027.6-9.9-3028.2-9.9
				L-3028.2-9.9z M-3030.2-0.1h3.8c0,0.6,0,1.3,0,2h-3.9C-3030.3,1.2-3030.2,0.5-3030.2-0.1L-3030.2-0.1z M-3041,1.8v-13.7h1.9V1.8
				H-3041z M-3044.9,1.8v-13.7h2V1.8H-3044.9z M-3044.3-13.8l2.3-4.6l2.3,4.6H-3044.3z M-3019.7,20h-17.2c0.4-1.2,1.5-1.9,2.8-2
				h11.7C-3021.2,18-3020.1,18.8-3019.7,20L-3019.7,20z M-3025.4,16.1h-5.9v-4.5h5.9V16.1z M-3008.2,9.6h-40.2
				c-1.6,0-2.9-1.3-2.9-2.9V3.8h46v2.9C-3005.3,8.3-3006.6,9.6-3008.2,9.6L-3008.2,9.6z"
              />
              <path
                id="Path_40_1_"
                d="M-3026.4,5.7h-5.9c-0.5,0-1,0.4-1,1c0,0,0,0,0,0l0,0c0,0.5,0.4,1,1,1h5.9
				c0.5,0,1-0.4,1-1l0,0C-3025.4,6.2-3025.8,5.7-3026.4,5.7z"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  )
}

export default IllustrationIcon
