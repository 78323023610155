import React, { useEffect } from "react"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Scrollbar from "smooth-scrollbar"

import SmoothScrollbar from "../components/scrollbar"
import SEO from "../components/seo"

import UiUxIcon from "../components/services-icons/uiUxIcon"
import IllustrationIcon from "../components/services-icons/illustrationIcon"
import BrandingIcon from "../components/services-icons/brandingIcon"

import Illustrator from "../images/toolkit/Illustrator.svg"
import AfterEffects from "../images/toolkit/AfterEffects.svg"
import InDesign from "../images/toolkit/InDesign.svg"
import LightroomClassic from "../images/toolkit/LightroomClassic.svg"
import Photoshop from "../images/toolkit/Photoshop.svg"
import AdobeXD from "../images/toolkit/XD.svg"
import Procreate from "../images/toolkit/Procreate.svg"

import "../styles/index.scss"

export const query = graphql`
  query HomePageQuery {
    allContentfulProject {
      nodes {
        coverImage {
          fluid(quality: 40, maxWidth: 1920) {
            base64
            aspectRatio
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        title
        slug
      }
    }
    portraitImage: file(relativePath: { eq: "portrait.png" }) {
      childImageSharp {
        fixed(width: 1000, quality: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    backgroundImage: file(relativePath: { eq: "background.png" }) {
      childImageSharp {
        fixed(width: 1000, quality: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  function scrollToBottom() {
    const scrollbar = Scrollbar.get(document.querySelector(".container"))
    scrollbar.scrollTo(0, scrollbar.getSize().content.height, 2048, {
      easing: function (x) {
        return x < 0.5
          ? 16 * x * x * x * x * x
          : 1 - Math.pow(-2 * x + 2, 5) / 2
      },
    })
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.to(".home-hero-portrait-image", {
      force3D: true,
      y: 300,
      ease: "none",
      scrollTrigger: {
        scrub: true,
        trigger: ".home-hero-portrait-image",
        start: "top bottom",
        end: "bottom top",
      },
    })

    gsap.to(".home-hero-background-image", {
      force3D: true,
      y: 600,
      ease: "none",
      scrollTrigger: {
        scrub: true,
        trigger: ".home-hero-background-image",
        start: "top bottom",
        end: "bottom top",
      },
    })
  }, [])

  return (
    <>
      <SEO />
      <SmoothScrollbar>
        <main className="home-wrapper">
          <div className="home-inner">
            <header className="home-hero-wrapper">
              <div className="home-hero-text-wrapper">
                <div className="home-hero-text-container">
                  <div className="home-hero-vertical-line"></div>
                  <div className="home-hero-text-inner">
                    <h2>Bilgesu Korkmaz</h2>
                    <h3>Web, UI/UX and Graphic Designer</h3>
                    <p>For design inquiries, please contact me.</p>
                  </div>
                </div>
                <button onClick={scrollToBottom}>Contact</button>
              </div>
              <div className="home-hero-image-wrapper">
                <div className="home-hero-image-div">
                  <Img
                    fixed={data.portraitImage.childImageSharp.fixed}
                    className="home-hero-portrait-image"
                    style={{ position: "absolute" }}
                  />
                  <Img
                    fixed={data.backgroundImage.childImageSharp.fixed}
                    className="home-hero-background-image"
                    style={{ position: "absolute" }}
                  />
                </div>
              </div>
            </header>
            <section className="home-services-wrapper">
              <div className="home-services-inner">
                <div className="home-service-wrapper">
                  <BrandingIcon width="50px" height="50px" />
                  <h4>Branding</h4>
                  <ul>
                    <li>Brand Identity Design</li>
                    <li>Logo Exploration & Development</li>
                    <li>Brand Exploration</li>
                    <li>Brand Collateral</li>
                  </ul>
                </div>
                <div className="home-service-wrapper">
                  <UiUxIcon width="50px" height="50px" />
                  <h4>UI/UX Design</h4>
                  <ul>
                    <li>Interface & Visual Design</li>
                    <li>Application & Responsive Web Design</li>
                    <li>Design Systems</li>
                    <li>Interaction Design</li>
                  </ul>
                </div>
                <div className="home-service-wrapper">
                  <IllustrationIcon width="40px" height="40px" />
                  <h4>Illustration & Font Design</h4>
                  <ul>
                    <li>Font Design & Development</li>
                    <li>Typography</li>
                    <li>Custom Lettering Design</li>
                    <li>Illustration</li>
                  </ul>
                </div>
              </div>
            </section>
            <section className="home-toolkit-wrapper">
              <div className="home-toolkit-inner">
                <img src={Illustrator} alt="Adobe Illustrator Logo" />
                <img src={AdobeXD} alt="Adobe XD Logo" />
                <img src={Photoshop} alt="Adobe Photoshop Logo" />
                <img src={AfterEffects} alt="Adobe After Effects Logo" />
                <img
                  src={LightroomClassic}
                  alt="Adobe Lightroom Classic Logo"
                />
                <img src={InDesign} alt="Adobe InDesign Logo" />
                <img src={Procreate} alt="Procreate Logo" />
              </div>
            </section>
            <section className="home-projects-wrapper">
              <div className="home-projects-inner">
                {data.allContentfulProject.nodes.map((project, index) => (
                  <div className="home-project-wrapper" key={index}>
                    <Link to={`/${project.slug}`} className="home-project-link">
                      <Img
                        fluid={project.coverImage.fluid}
                        className="home-project-cover"
                      />
                    </Link>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </main>
      </SmoothScrollbar>
    </>
  )
}

export default IndexPage
