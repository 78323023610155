import React from "react"

const BrandingIcon = ({ width, height }) => {
  return (
    <>
      <svg
        version="1.1"
        baseProfile="tiny"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={width}
        height={height}
        viewBox="0 0 49.9 47.8"
        overflow="visible"
      >
        <g id="Group_1-2_1_" transform="translate(1194.297 -181.245)">
          <g id="Group_6_1_" transform="translate(-2009.297 -1531.755)">
            <g id="Group_2-2_1_" transform="translate(815 1713)">
              <path
                id="Path_22_1_"
                d="M46,9c-0.1-0.4-0.5-0.6-0.9-0.5l0,0c-0.4,0.1-0.6,0.5-0.5,0.9c0,0,0,0,0,0l0,0
				c0.8,2.6-0.3,5.5-2.7,6.9l-0.3,0.2c-0.2,0.1-0.4,0.4-0.4,0.6V18h-1.7v-3c0.8-0.3,1.3-1.1,1.3-1.9v-1.6c0-1.1-0.9-2-2-2h-0.1
				c-1.1,0-2,0.9-2,2v1.6c0,0.9,0.5,1.6,1.3,1.9v3h-1.7v-0.9c0-0.3-0.1-0.5-0.4-0.6l-0.3-0.2c-0.9-0.5-1.6-1.3-2.1-2.2
				c-1.7-2.9-0.7-6.6,2.2-8.3c2.3-1.3,5.3-1,7.2,0.9c0.3,0.3,0.8,0.3,1,0c0.3-0.3,0.3-0.8,0-1l0,0c-3-2.9-7.7-2.9-10.6,0.1
				c-2.9,3-2.9,7.7,0.1,10.6c0.4,0.4,0.9,0.8,1.4,1.1v0.6c-0.4,0.2-0.7,0.6-0.7,1v3.3c0,0.6,0.5,1.2,1.2,1.2h1.1v1
				c0,0.7,0.6,1.3,1.3,1.3h2.1c0.7,0,1.3-0.6,1.3-1.3v-1h1.1c0.6,0,1.2-0.5,1.2-1.2v-3.3c0-0.4-0.3-0.9-0.7-1v-0.6
				c1.1-0.7,2-1.6,2.6-2.7C46.3,13.1,46.6,10.9,46,9L46,9z M38.1,11.5c0-0.3,0.3-0.6,0.6-0.6h0.1c0.3,0,0.6,0.3,0.6,0.6v1.6
				c0,0.3-0.3,0.6-0.6,0.6c0,0,0,0,0,0h-0.1c-0.3,0-0.6-0.3-0.6-0.6V11.5z M39.6,24.5h-1.7v-0.8h1.7V24.5z M41.9,22.2h-6.2v-2.8h6.2
				V22.2z"
              />
              <path
                id="Path_23_1_"
                d="M38.8,2.7c0.4,0,0.7-0.3,0.7-0.7V0.7c0-0.4-0.3-0.7-0.7-0.7c0,0,0,0,0,0l0,0
				C38.4,0,38,0.3,38,0.7c0,0,0,0,0,0v1.2C38,2.3,38.4,2.7,38.8,2.7C38.8,2.7,38.8,2.7,38.8,2.7z"
              />
              <path
                id="Path_24_1_"
                d="M34.3,3.1c0.1,0.3,0.4,0.4,0.7,0.4c0.1,0,0.2,0,0.3-0.1c0.4-0.2,0.5-0.6,0.4-1h0
				l-0.5-1.1C35,1,34.5,0.8,34.2,1l0,0c-0.4,0.2-0.5,0.6-0.4,1l0,0L34.3,3.1z"
              />
              <path
                id="Path_25_1_"
                d="M30.4,4.8l0.9,0.8c0.3,0.3,0.8,0.2,1-0.1c0.3-0.3,0.2-0.8-0.1-1l0,0l-0.9-0.8
				c-0.3-0.3-0.8-0.2-1,0.1l0,0C30.1,4.1,30.1,4.5,30.4,4.8z"
              />
              <path
                id="Path_26_1_"
                d="M28.5,8.7l1.2,0.4c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9l0,0L29,7.4
				c-0.4-0.1-0.8,0.1-0.9,0.5c0,0,0,0,0,0l0,0C27.9,8.2,28.1,8.6,28.5,8.7C28.5,8.7,28.5,8.7,28.5,8.7L28.5,8.7z"
              />
              <path
                id="Path_27_1_"
                d="M28.3,13.1C28.3,13.1,28.4,13.1,28.3,13.1l1.3-0.1c0.4,0,0.7-0.4,0.6-0.8c0,0,0,0,0,0
				l0,0c0-0.4-0.4-0.7-0.8-0.6c0,0,0,0,0,0h0l-1.2,0.1c-0.4,0-0.7,0.4-0.6,0.8l0,0C27.6,12.9,27.9,13.1,28.3,13.1z"
              />
              <path
                id="Path_28_1_"
                d="M29.3,15.9c-0.3,0.2-0.5,0.7-0.3,1c0,0,0,0,0,0l0,0c0.2,0.3,0.6,0.5,1,0.3c0,0,0,0,0,0
				l1-0.6c0.3-0.2,0.5-0.7,0.3-1l0,0c-0.2-0.3-0.7-0.5-1-0.3c0,0,0,0,0,0l0,0L29.3,15.9z"
              />
              <path
                id="Path_29_1_"
                d="M42.3,3.4c0.4,0.2,0.8,0,1-0.4c0,0,0,0,0,0L43.7,2c0.2-0.4,0-0.8-0.4-1c0,0,0,0,0,0l0,0
				c-0.4-0.2-0.8,0-1,0.4l-0.5,1.1C41.7,2.8,41.9,3.3,42.3,3.4L42.3,3.4z"
              />
              <path
                id="Path_30_1_"
                d="M45.7,5.8c0.2,0,0.4-0.1,0.5-0.2l0.9-0.8c0.3-0.3,0.3-0.7,0-1c-0.3-0.3-0.7-0.3-1-0.1
				l0,0l-0.9,0.8c-0.3,0.3-0.3,0.7-0.1,1C45.3,5.7,45.5,5.8,45.7,5.8L45.7,5.8z"
              />
              <path
                id="Path_31_1_"
                d="M46.9,8.6C47,9,47.4,9.2,47.8,9.1c0,0,0,0,0,0L49,8.7c0.4-0.1,0.6-0.5,0.5-0.9
				c-0.1-0.4-0.5-0.6-0.9-0.5l0,0l-1.2,0.4C47,7.8,46.8,8.3,46.9,8.6z"
              />
              <path
                id="Path_32_1_"
                d="M49.3,11.7l-1.2-0.1c-0.4,0-0.8,0.2-0.8,0.6v0c0,0.4,0.2,0.8,0.6,0.8c0,0,0,0,0,0
				l1.2,0.1c0,0,0.1,0,0.1,0c0.4,0,0.7-0.3,0.7-0.7C49.9,12,49.6,11.7,49.3,11.7L49.3,11.7z"
              />
              <path
                id="Path_33_1_"
                d="M48.2,15.9l-1-0.6c-0.3-0.2-0.8-0.1-1,0.3c-0.2,0.3-0.1,0.8,0.3,1c0,0,0,0,0,0l0,0l1,0.6
				c0.1,0.1,0.2,0.1,0.4,0.1c0.4,0,0.7-0.3,0.7-0.7C48.5,16.3,48.4,16.1,48.2,15.9L48.2,15.9z"
              />
              <path
                id="Path_34_1_"
                d="M8.2,16.9h7.4c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c0,0,0,0,0,0H8.2
				c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0l0,0C7.4,16.5,7.7,16.9,8.2,16.9C8.1,16.9,8.2,16.9,8.2,16.9z"
              />
              <path
                id="Path_35_1_"
                d="M4.7,35.3H19c0.4,0,0.7-0.3,0.7-0.7l0,0c0-0.4-0.3-0.7-0.7-0.7c0,0,0,0,0,0H4.7
				c-0.4,0-0.7,0.3-0.7,0.7C4,34.9,4.3,35.3,4.7,35.3C4.7,35.3,4.7,35.3,4.7,35.3L4.7,35.3z"
              />
              <path
                id="Path_36_1_"
                d="M28.6,27.5c0-2.4-1.9-4.3-4.3-4.3S20,25.1,20,27.5c0,2.4,1.9,4.3,4.3,4.3
				C26.7,31.8,28.6,29.9,28.6,27.5z M24.3,24.6c1.5,0,2.8,1.2,2.9,2.7c-1.6,0.2-3-0.9-3.2-2.5c0-0.1,0-0.1,0-0.2
				C24.1,24.6,24.2,24.6,24.3,24.6L24.3,24.6z M21.4,27.5c0-0.9,0.4-1.7,1.1-2.3c0.4,2.1,2.2,3.6,4.3,3.6h0
				c-0.7,1.4-2.5,1.9-3.9,1.2C22,29.5,21.4,28.5,21.4,27.5L21.4,27.5z"
              />
              <path
                id="Path_37_1_"
                d="M42,39l-3.7-2c-0.6-0.3-1.2-0.5-1.8-0.5H23.7v-2.3c0.2,0,0.4,0,0.6,0
				c4.4,0,8.4-2.3,10.6-6.1c0.2-0.4,0.2-0.9,0-1.2c-2.2-3.8-6.2-6.1-10.6-6.1c-0.2,0-0.4,0-0.6,0v-9.1c0-0.7-0.5-1.2-1.2-1.2H9.1
				c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0l0,0c0,0.4,0.3,0.7,0.7,0.7c0,0,0,0,0,0h13.2v9c-0.5,0.1-1,0.2-1.5,0.4v-0.6
				c0-0.7-0.5-1.2-1.2-1.2H4.2c-0.7,0-1.2,0.5-1.2,1.2v10.2C3,31.5,3.5,32,4.2,32h13.1c1.5,1,3.2,1.7,4.9,2v2.5h-8.8
				c-0.5,0-1,0.3-1.3,0.7H4.7C4.3,37.3,4,37.6,4,38s0.3,0.7,0.7,0.7c0,0,0,0,0,0h7.2v2H4.7c-0.4,0-0.7,0.3-0.7,0.7
				c0,0.4,0.3,0.7,0.7,0.7h7.2c0.1,0.8,0.7,1.3,1.5,1.3h8.8v2.8H1.5V11.9h4.1c0.4,0,0.7-0.3,0.7-0.7l0,0c0-0.4-0.3-0.7-0.7-0.7
				c0,0,0,0,0,0H1.2C0.5,10.4,0,11,0,11.6v35c0,0.7,0.5,1.2,1.2,1.2h21.3c0.7,0,1.2-0.5,1.2-1.2v-3.1h12.7c0.6,0,1.3-0.2,1.8-0.5
				l3.7-2c0.6-0.2,0.8-0.9,0.6-1.5C42.5,39.3,42.2,39.1,42,39L42,39z M16.6,42.1h-3.1c0,0-0.1,0-0.1-0.1v-4c0,0,0-0.1,0.1-0.1h3.1
				L16.6,42.1z M4.5,30.6v-9.7h14.8v1c-2.3,1.1-4.3,2.8-5.5,5c-0.2,0.4-0.2,0.9,0,1.2c0.5,0.9,1.2,1.7,1.9,2.5L4.5,30.6z M15,27.5
				c3-5.1,9.6-6.8,14.7-3.8c1.6,0.9,2.9,2.2,3.8,3.8c-3,5.1-9.6,6.8-14.7,3.8C17.3,30.3,16,29,15,27.5z M35.4,39.3h-3
				c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h3v1.3H18v-1.3H29c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H18V38h17.4
				L35.4,39.3z M38.4,41.3l-0.8,0.5c-0.2,0.1-0.5,0.2-0.7,0.2v-4c0.3,0,0.5,0.1,0.7,0.2l0.8,0.5V41.3z M39.9,40.5v-1l0.9,0.5
				L39.9,40.5z"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  )
}

export default BrandingIcon
